import React, {useState, useEffect} from "react"
import {Container,Button,Navbar,Nav} from 'react-bootstrap';
import { Link, navigate } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-input-range/lib/css/index.css'
import ProfileImg1 from "../../assests/images/profile1.png";
import SEO from "../../components/seo"

import Header from  "../../components/Common/Header"; 
import NavLink from  "../../components/NavLink/Custom_Presentation"; 

import {config} from '../../components/Common/constant';

import HTMLReactParser from 'html-react-parser';
import { GET_ARTICLE } from "../../queries/common_use_query";

import { submitPresentationStatus } from "../../queries/api_links";
import {uniqueID} from "../../components/utils/common";
import { useContainerContext } from "../../store/ContainerContext";

import VideoModule from "../../components/modules/video-module"

const PresentationPage = () => {
    var strapi_url = process.env.GATSBY_STRAPI_SRC;
    var uniqueID_1 = uniqueID(); 
    var uniqueID_2 = uniqueID(); 

    const context = useContainerContext();
    const { property_data } = context.state;

    const [ article_data, setArticleData ] = useState('');
    const { loading, error, data } = GET_ARTICLE('custom-presentation-confirmation');

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);

    const submitData = async(property_id, present_type, present_status, uniqueID_1, appointment_id, seller_fact_find, uniqueID_2, SignInAgreement, PaymentOption, CustomPresentation, preval_preview_url) => {
        const updateQry = submitPresentationStatus(property_id, present_type, present_status, uniqueID_1, appointment_id, seller_fact_find, uniqueID_2, SignInAgreement, PaymentOption, CustomPresentation, preval_preview_url).then(res => {
            if(res && res.status === 200){
                //console.log("success_msg", property_id+'-success');
                navigate(config.app_dashboard);
            } else{

            }
        });
    }

    var SignInAgreement = {
        "FullName": "Test Nandy",
        "InitialName": "Mr",
        "Declartions": "test Desclaration",
        "SignImage": "www.image.com"
    },
    PaymentOption = {
        "OnCompletionFee": "1700000",
        "PrepaidPrice": "",
	    "FormerClient": true,
	    "MarketingPrice": "888"
    },
    CustomPresentation = {
        "PropertyLaunchDateTime": "2021-03-08T07:26:50",
        "PropertyLaunchUser": "Test User",
        "PhotographyDateAndTime360": "2021-03-08T07:26:50",
        "PhotographyUser360": "Phootgraphy username"
    },
    seller_fact_find = property_data.seller_fact_find,
    urls = property_data.urls;

    //console.log("PrevalPresentationURL", urls.PrevalPresentationURL);

    return <>
        <SEO title={article_data && article_data.Meta_Title} description={article_data && article_data.Meta_Description} />
        
        <div className="pre-wrapper">

        <Header />

        {/* <div className="contact-wrapper custom_present contact_section">
            <Container>
            <div className="contact-wrap">
                <div className="contact-content">
                <h2>{article_data && article_data.Modules[0].Title} <br /><span>Friday 12th Sept</span> at <span>10:00am</span>.</h2>
                
                {article_data && article_data.Modules[0].CTA_1_Label && <a href="javascript:;" className="btn btn-primary" onClick={() => submitData(property_data.id, 'custom', 'sent', uniqueID_1, property_data.appointment.Id, seller_fact_find, uniqueID_2, SignInAgreement, PaymentOption, CustomPresentation)}>{article_data.Modules[0].CTA_1_Label}</a> }

                {article_data && article_data.Modules[0].CTA_2_Label && <Link to={article_data.Modules[0].CTA_2_URL ? article_data.Modules[0].CTA_2_URL : '#'} className="btn btn-secondary">{article_data.Modules[0].CTA_2_Label}</Link> }
            </div>
            </div>
            </Container>                     
            <div className="contact-img">
                { article_data && article_data.Modules[0].Upload_Image && <img src={strapi_url+''+article_data.Modules[0].Upload_Image.url} alt="" /> }
            </div>
        </div> */}

        <div className="contact-wrapper intro_section">
          <Container>
            <div className="contact-wrap">
                <div className="contact-content">
                    <h2>{article_data && article_data.Modules[0].Title} <br /><span>Friday 12th Sept</span> at <span>10:00am</span>.</h2>
                    
                    {article_data && article_data.Modules[0].Content && <p class="fig-content">{HTMLReactParser(article_data.Modules[0].Content)}</p>}

                    {article_data && article_data.Modules[0].CTA_1_Label && <a href="javascript:;" className="btn btn-primary" onClick={() => submitData(property_data.id, 'custom', 'sent', uniqueID_1, property_data.appointment.Id, seller_fact_find, uniqueID_2, SignInAgreement, PaymentOption, CustomPresentation, (urls.PrevalPresentationURL ? urls.PrevalPresentationURL : ''))}>{article_data.Modules[0].CTA_1_Label}</a> }

                    {article_data && article_data.Modules[0].CTA_2_Label && <Link to={article_data.Modules[0].CTA_2_URL ? article_data.Modules[0].CTA_2_URL : '#'} className="btn btn-secondary">{article_data.Modules[0].CTA_2_Label}</Link> }
                </div>
                {/* contact-content */} 

                <div className="employment-right">
                    <div className="video-wrap">
                        <VideoModule video_url={article_data && article_data.Modules && article_data.Modules.length > 0 && article_data.Modules[0].Embed_Video_URL} playing={true} loop={true} />
                    </div>
                </div>
                {/* video-wrap */}
            </div>
            {/* contact-wrap */}
          </Container>                     
      </div>
      {/* contact-wrapper */}

        
        {/* contact-wrapper */}


        <NavLink tab_name="Thank You" prevLink={config.custom_contract_form} nextLink="" />

        </div>
    </>
}

export default PresentationPage